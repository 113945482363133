import styled from 'styled-components';

import { color, media } from 'components/_settings/variables';

import IconSvg from './icon.svg';

export const Wrapper = styled.div`
  position: absolute;
  top: -8px;
  right: 0;
  max-width: 467px;
  background-color: ${color.text.tertiary};
  border-radius: 8px;
  box-shadow: 14px 36px 150px -45px rgba(50, 50, 93, 0.5), 2px 2px 12px 0 rgba(26, 26, 67, 0.05);
  z-index: 1;

  ${media.md} {
    position: relative;
    top: 0;
    max-width: 100%;
  }

  form {
    padding: 25px 50px 50px;

    ${media.xs} {
      padding: 25px;
    }

    & > div {
      margin-bottom: 15px;
    }
  }
`;

export const FormHeader = styled.div`
  border-bottom: 1px solid #eaeef2;
  padding: 32px 50px;
  display: flex;

  ${media.xs} {
    padding: 32px 25px;
  }
`;

export const Icon = styled(IconSvg)`
  width: 56px;
  height: 56px;
  margin-right: 32px;
  flex-shrink: 0;
`;

export const HeaderContent = styled.div`
  color: #56556c;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 1.17;
  margin-bottom: 4px;

  ${media.xs} {
    font-size: 16px;
    line-height: 28px;
  }
`;

export const Description = styled.div`
  line-height: 1.75;
  font-size: 16px;

  ${media.xs} {
    font-size: 14px;
    line-height: 24px;
  }
`;
