import * as React from 'react';

import { Grid, GridCell } from 'components/shared/grid';

import {
  Wrapper,
  Inner,
  Content,
  Label,
  StyledTitle,
  Description,
  BackgroundWrapper,
  BackgroundFigure,
} from './case-study-hero.styles';
import { Form } from './form';

export const Hero = ({ label, title, description, formTitle, formDescription, formData }) => (
  <Wrapper>
    <BackgroundWrapper>
      <BackgroundFigure />
    </BackgroundWrapper>
    <Inner>
      <Grid columns={2} gap={80} media={{ md: { columns: 1, gap: 40 } }}>
        <GridCell>
          <Content>
            <Label>{label}</Label>
            <StyledTitle as="h1" size="xl">
              {title}
            </StyledTitle>
            <Description>{description}</Description>
          </Content>
        </GridCell>
        <GridCell>
          <Form title={formTitle} description={formDescription} formData={formData} />
        </GridCell>
      </Grid>
    </Inner>
  </Wrapper>
);
