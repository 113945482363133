import styled from 'styled-components';

import { fontSize, media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Title } from 'components/shared/title';

import BackgrounfFigureSvg from './background-figure.svg';

export const Description = styled.p`
  font-size: ${fontSize.lg};
  line-height: 1.58;
  color: #e2c8ff;
  margin-bottom: 40px;
  opacity: 0.73;

  ${media.sm} {
    font-size: ${fontSize.md};
    margin-bottom: 20px;
  }
`;

export const StyledTitle = styled(Title)`
  color: #e2c8ff;
  margin-bottom: 40px;

  ${media.sm} {
    margin-bottom: 20px;
  }
`;

export const Label = styled.div`
  font-size: ${fontSize.lg};
  line-height: 1.75;
  color: rgba(226, 200, 255, 0.5);
  margin-bottom: 27px;
  font-weight: 500;
`;

export const Content = styled.div`
  max-width: 560px;

  ${media.sm} {
    max-width: 400px;
  }
`;

export const Inner = styled(Container)`
  position: relative;
`;

export const Wrapper = styled.section`
  position: relative;
  padding-top: 200px;
  padding-bottom: 185px;
  margin-bottom: 85px;

  ${media.md} {
    padding-bottom: 0;
  }
`;

export const BackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #07112e;

  ${media.md} {
    max-height: 75vh;
  }
`;

export const BackgroundFigure = styled(BackgrounfFigureSvg)`
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
`;
