import styled from 'styled-components';

import { media } from 'components/_settings/variables';

import { Container as SharedContainer } from '../../../shared/container';

export const Container = styled(SharedContainer)`
  padding-bottom: 90px;
`;

export const Wrapper = styled.article``;

export const PageContent = styled.div`
  max-width: 570px;

  ${media.lg} {
    max-width: 460px;
  }

  ${media.md} {
    max-width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: normal;
    color: #2c295d;
    margin-bottom: 24px;

    ${media.lg} {
      margin-bottom: 20px;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  p {
    font-size: 16px;
    line-height: 28px;
    color: #6b7696;
    margin-bottom: 48px;

    ${media.lg} {
      margin-bottom: 30px;
    }
  }
`;
