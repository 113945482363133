import * as React from 'react';

import { SuccessModal } from 'components/blocks/success-modal';
import { FormGenerator } from 'components/shared/form-generator';

import {
  Wrapper,
  FormHeader,
  Icon,
  HeaderContent,
  Title,
  Description,
} from './case-study-form.styles';

export const Form = (props) => {
  const { title, description, formData } = props;
  const [successMessage, setSuccessMessage] = React.useState(null);
  return (
    <Wrapper id="form">
      <FormHeader>
        <Icon />
        <HeaderContent>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </HeaderContent>
      </FormHeader>
      <FormGenerator
        data={formData}
        buttonText={formData.button.text}
        onSuccessfulFormSubmit={() => setSuccessMessage(formData.confirmations[0].message)}
      />

      {successMessage && (
        <SuccessModal message={successMessage} onClose={() => setSuccessMessage(null)} />
      )}
    </Wrapper>
  );
};
