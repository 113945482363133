import { graphql } from 'gatsby';
import React from 'react';

import Layout from 'components/_settings/layout';
import { Footer } from 'components/blocks/footer';
import { Header } from 'components/blocks/header';
import { Content } from 'components/pages/case-study-new/content';
import { Hero } from 'components/pages/case-study-new/hero';
import { buildPageMetadata } from 'utils/data-builders';

const CaseStudyNew = (props) => {
  const {
    data: { wpPage, form },
  } = props;
  const { content, acf } = wpPage;

  return (
    <Layout pageMetadata={buildPageMetadata(wpPage)}>
      <Header withoutActions={!acf.showHeader} theme="light" />
      <Hero
        label={acf.hero.textAboveTitle}
        title={acf.hero.title}
        description={acf.hero.description}
        formTitle={acf.form.title}
        formDescription={acf.form.description}
        formData={form}
      />
      <Content content={content} />
      {acf.showFooter && <Footer theme="light" />}
    </Layout>
  );
};

export default CaseStudyNew;

export const query = graphql`
  query ($id: String!, $formId: Int!) {
    wpPage(id: { eq: $id }) {
      ...wordpressPagePageMetadata
      content
      acf {
        hero {
          textAboveTitle
          title
          description
        }

        form {
          title
          description
        }

        showHeader
        showFooter
      }
    }

    form: gfForm(formId: { eq: $formId }) {
      formId
      apiURL
      formFields {
        id
        label
        type
        defaultValue
        isRequired
        choices
        conditionalLogic
        description
        cssClass
      }
      confirmations {
        message
      }
      button {
        text
      }
    }
  }
`;
