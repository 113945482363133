import React from 'react';

import { Container, Wrapper, PageContent } from './case-study-content.styles';

export const Content = ({ content }) => (
  <Container>
    <Wrapper>
      <PageContent dangerouslySetInnerHTML={{ __html: content }} />
    </Wrapper>
  </Container>
);
